/* RealEstatePage.module.css */
.section {
  padding: 90px 20px; /* Adjusted padding */
  margin-bottom: 20px; /* Reduced margin */
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  color: #83d662;
  z-index: 1100;
}

.realEstateHeader {
  padding: 20px 20px;
  margin-bottom: 10px;
  color: #62a8c2;
  text-align: center;
  
}

.real-estate-map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 auto;
}

/* Adjust height for smaller screens */
@media (max-width: 1200px) {
  .real-estate-map-container {
    height: 200vh;
  }
}

@media (max-width: 768px) {
  .real-estate-map-container {
    height: 150vh;
  }
}

@media (max-width: 480px) {
  .real-estate-map-container {
    height: 100vh;
  }
}

.map-container {
  width: 100%;
  max-width: 800px;
}

.map-container svg {
  width: 100%;
  height: auto;
}

.sales-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.sale-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
}

.sale-item img {
  max-width: 100%;
  border-radius: 8px;
}

.agent-bio {
  cursor: pointer; /* Change the cursor to a pointer */
  color: #6420a1;
  margin-top: 10px;
}

.real-estate-description {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.real-estate-link {
  color: #1a73e8;
  text-decoration: none;
}

.real-estate-link:hover {
  text-decoration: underline;
}

.real-estate-blog {
  list-style: disc;
  margin-left: 20px;
}

.real-estate-blog li {
  margin-bottom: 10px;
}
