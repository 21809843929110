/* Modal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
  padding-top: 60px;
  overflow-y: auto;
}

.modalContent {
  background: #62c269;
  color: #031b36;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 90%;
  width: auto;
  max-height: calc(80vh - 60px);
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: left;
  font-family: 'Merriweather', serif;
}

.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.modalContent h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.modalContent p {
  line-height: 1.6;
  font-size: 1.1em;
  margin-bottom: 15px;
}

.modalContent a {
  color: #2771c5;
  text-decoration: none;
}

.modalContent a:hover {
  text-decoration: underline;
}

.modalContent .localBusinesses {
  margin-top: 20px;
  font-weight: bold;
  color: black;
}

.modalContent img {
  max-height: 150px; /* Adjust the max height of images in modal */
  width: auto;
  max-width: 100%;
  display: block;
  margin-bottom: 15px;
}

.agentInfo img {
  max-height: 150px; /* Adjust the max height of agent images in modal */
  width: auto;
  max-width: 100%;
  display: block;
  margin-bottom: 15px;
}

/* Additional styles for bio toggle */
.agentBio {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 10px;
}
