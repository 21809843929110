/* ArtSlideshow.css */

.art-slideshow {
  width: 100%; /* Adjust this as necessary */
  max-width: 600px; /* Adjust this as necessary */
  margin: 0 auto; /* Center the slideshow */
}

.slide-image {
  width: 100%;
  height: 300px; /* Set the desired height */
  object-fit: cover; /* This will crop the image to fit the container while maintaining aspect ratio */
}
