/* SearchBar.css */
.search-bar {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .search-bar input {
    padding: 10px;
    font-size: 16px;
    width: 80%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  