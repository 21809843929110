.highlights-container {
  padding: 15px;
  background-color: #333;
  color: #fff;
}

.highlights-header {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.search-description {
  font-size: 0.6em;
  margin-bottom: 10px;
  color: #ccc;
}

.newest-post-header {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #ff6600;
}

.newest-post {
  background-color: #444;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newest-post .highlight-post-image {
  width: 60%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
}

.highlights-posts {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.highlight-post-link {
  text-decoration: none;
  color: inherit;
  flex: 1 1 calc(33% - 15px);
}

.highlight-post {
  background-color: #444;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.highlight-post .highlight-post-title {
  font-size: 1.2em;
  margin-bottom: 8px;
}

.highlight-post .highlight-post-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
  object-fit: cover;
}

.highlight-post .highlight-post-author,
.highlight-post .highlight-post-date {
  font-size: 0.8em;
  color: #aaa;
  margin-bottom: 8px;
}

.highlight-post .highlight-post-categories {
  font-size: 0.7em;
  color: #bbb;
  margin-bottom: 8px;
}

.highlight-post .highlight-post-snippet {
  font-size: 0.8em;
  color: #ddd;
  margin-bottom: 8px;
}

.read-more-link-container {
  text-align: right;
}

.read-more-link {
  display: inline-block;
  margin-top: 5px;
  font-size: 0.8em;
  color: #ff6600;
  text-decoration: none;
}

.read-more-link:hover {
  text-decoration: underline;
}

.highlight-post-even {
  display: flex;
  flex-direction: column;
}

.highlight-post-odd {
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 768px) {
  .highlight-post-link {
    flex: 1 1 100%;
  }
}
