.homePage {
  position: relative;
  overflow: hidden;
}

.homeSection {
  position: relative;
  overflow: hidden;
}

.homeContent {
  padding: 20px;
}

.homeHeader {
  text-align: left;
}

.animatedSpaceshipContainer {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure it's relative for child absolute positioning */
}
