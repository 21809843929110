.real-estate-map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: -20px;
  margin: 0 auto; 
}

.map-container {
  width: 100%;
  max-width: 800px;
  padding: 0;
}

.map-container svg {
  width: 100%; 
  height: auto; 
}

.map .town {
  cursor: pointer;
  fill: #ccc;
  stroke: #000;
}

.map .town:hover {
  fill: #aaa;
}
