/* src/components/Slideshow/Slideshow.css */
.slideshow {
    margin: 20px auto;
    width: 80%;
  }
  
  .slide-image {
    width: 100%;
    height: 300px; /* Define a consistent height */
    object-fit: cover; /* Ensures the image covers the container */
    border-radius: 15px;
  }
  