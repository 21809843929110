/* Ensure the container for interactive content is above other elements */
.reiki-container {
  margin-top: 60px;
  color: #fff;
  padding-bottom: 60px;
  position: relative;
  z-index: 10; /* Higher z-index to ensure it's above the background effects */
}

.reiki-title {
  margin-top: 60px;
  font-size: 2.5em;
  color: #fff; 
}

.reiki-description {
  font-size: 1.2em;
  color: #fff; 
}

.reiki-member {
  text-align: center;
  margin-bottom: 30px;
}

.reiki-thumbnail-container {
  position: relative;
  overflow: hidden;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.reiki-thumbnail-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  transition: transform 0.3s ease;
  border-radius: 15px; 
}

.reiki-thumbnail-container:hover .reiki-thumbnail-image {
  transform: scale(1.1); 
}

.reiki-name {
  font-size: 1.5em;
  margin-top: 15px;
  color: #fff; 
}

/* Ensure links are clickable above any background animations */
.disclosure-link, ul li a {
  position: relative;
  z-index: 10; /* Ensure it is above the animation */
  color: #fff;
  text-decoration: underline;
}

/* Pulsing animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  }
}

.pulsing {
  animation: pulse 2s infinite;
  border-radius: 15px;
}

.chakra-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.chakra {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.7));
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.chakra-root {
  background: radial-gradient(circle, red, transparent);
}

.chakra-sacral {
  background: radial-gradient(circle, orange, transparent);
}

.chakra-solar {
  background: radial-gradient(circle, yellow, transparent);
}

.chakra-heart {
  background: radial-gradient(circle, green, transparent);
}

.chakra-throat {
  background: radial-gradient(circle, blue, transparent);
}

.chakra-third-eye {
  background: radial-gradient(circle, indigo, transparent);
}

.chakra-crown {
  background: radial-gradient(circle, violet, transparent);
}

/* Ensure background is lower in the stacking context */
.reiki-background {
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3));
  animation: bg-animation 10s infinite alternate;
  overflow: auto;
  position: relative;
  z-index: 1; /* Lower z-index to make sure it's below the content */
}

@keyframes bg-animation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.paymentSection {
  padding: 40px 20px;
  text-align: center;
  background-color: rgba(17, 16, 16, 0.9);
  margin: 40px 0;
  border-radius: 10px;
}

.paymentSection h2 {
  color: white;
  font-size: 2em;
  margin-bottom: 20px;
}

.paymentSection p {
  color: white;
  margin-bottom: 20px;
}

.paymentTags {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.paymentTag {
  text-align: center;
}

.paymentImage {
  width: 150px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.paymentTag p {
  color: white;
  font-size: 1.2em;
  margin-top: 10px;
}

.disclosure-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #623686;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  font-weight: bold;
  transition: background-color 0.3s ease;
  z-index: 15; 
}

.disclosure-button:hover {
  background-color: #6d20c5d7;
  color: #fff;
  text-decoration: none;
}
